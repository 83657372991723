import React from "react";
import styled from "styled-components";
import { Container, Col, Row } from "react-bootstrap";
import { Input, Section, Text } from "../../Core";
import { Link } from "gatsby";

const StyledSection = styled(Section)`
  background-color: #08DE9E;
  h2 {
    color: #000;
  }
`

const HeroCoverageAndNetwork = (props) => {
  return (
    <StyledSection>
      <Container>
        <Row>
          <Col sm={12}>
            <h2>Coverage and Network status<br />checker</h2>
          </Col>
        </Row>
      </Container>
    </StyledSection>
  )
};

export default HeroCoverageAndNetwork;
