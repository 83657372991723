import React from "react";
import { Section, Text } from "../components/Core";
import { Col, Container, Row } from "react-bootstrap";
import GlobalContext from "../context/GlobalContext";
import Seo from "../components/Seo/Seo";
import styled from "styled-components";
import HeroCoverageAndNetwork from "../components/Rebrand/Hero/HeroCoverageAndNetwork";
import { Tabs, Tab } from 'react-bootstrap';

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
`

const StyledIframe = styled.iframe`
  height: 1200px;
  width: 100%;
  border: none;
`

const ServiceChecker = () => {

    return (
      <>
        <Seo page="support-list-view" />
        <HeroCoverageAndNetwork />
        <Section>
          <Container>
            <Row>
              <Col sm={12}>
                <Tabs defaultActiveKey="check" transition={false} id="noanim-tab-example">
                  <Tab eventKey="check" title="Check coverage">
                    <Text className="mb-3">
                      We’re not alone in doing this — other networks in the UK and
                      abroad are also phasing out their 3G networks. Want to find
                      out more about our 3G networks being switched off? Use our coverage
                      checker for information about your local area.
                    </Text>
                    <StyledIframe
                      src="https://www.three.co.uk/content/experience-fragments/threedigital/uk/en/site/mvno/coverage-checker/youfibre.html"
                      title="Coverage Checker"
                    />
                  </Tab>
                  <Tab eventKey="status" title="Network status">
                    <Text className="mb-3">
                      New to YouFibre Mobile? Use our coverage checker below to see
                      if you can access our award-winning network. If you’re already
                      with us, you can use our Network Status Checker to see if
                      we have maintenance going on.
                    </Text>
                    <StyledIframe
                      src="https://www.three.co.uk/content/experience-fragments/threedigital/uk/en/site/mvno/network-status-checker/youfibre.html"
                      title="Coverage Checker"
                    />
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </Container>
        </Section>
      </>
    )
}

export default ServiceChecker